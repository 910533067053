body {
  margin: 0;
  font-family: 'Exo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fdf6ed;
}

/* Elements */

.container--thin_margin {
  max-width: 90%;
}

.row--mobile_swipable_width{
  overflow-x: hidden;
  flex-wrap: nowrap;
}

.btn {
  border-radius: 1.2rem;
  border: 2px solid #F2A755;
  background: #F2A755;
  color: white;
}

.btn:hover {
  background: #E89C39;
  border: 2px solid #E89C39;
  color: white;
}

.btn:focus {
  background: #E89C39;
  border: 2px solid #E89C39;
  color: white;
  box-shadow: none; 
}

.main-row {
  max-width: 100vw;
  overflow-x: hidden;
}

/* Router */
.container__verify_email {
  text-align: center;
  padding-top: 20vh;
}


.container__reset_password {
  text-align: center;
  padding-top: 20vh;
}

.container__contact {
  text-align: center;
  padding-top: 10vh;
}

.container__contact__form {
  text-align: left;
}

/* Loading */

.full_page_loader {
  margin-top: 40vh;
  width: 5rem;
  height: 5rem;
  color: #F2A755;
}

.mobile_swipable_width {
  min-width: 100%;
}

/* Nav */

.fridge-navbar {
  background-color: white;
  box-shadow: 0px 10px 30px -20px rgba(0,0,0,0.75);
}

.fridge-navbar .dropdown-toggle:after {
  display: none;
}

.fridge-navbar .nav-link {
  padding: 0%;
  font-size: 12pt;
  font-weight: lighter;
}

.fridge-navbar .nav-link.active {
  color: #929292 !important; 
  padding-bottom: 0.2rem;
  border-bottom: 1px solid #929292; 
}

.navbar__view-selector {
  position: absolute;
  margin-left: 30%;
  width: 40%;
  justify-content: center;
}

.navbar__sign-in-button {
  font-size: 0.8rem;
  font-weight: bold;
}

.navbar__user__avatar {
  position: relative;
  height: 42px;
  width: 42px;
  border-radius: 21px;
  overflow: hidden;
  object-fit: cover;
}

.navbar__user__avatar__wrapper {
  padding-right: 0.5rem;
}

.navbar__user__score {
  color: black;
}

.navbar__user__handle {
  color: black;
  font-weight: bold;
}

/* Welcome Modal */

.welcome-modal__img-container--no-heading {
  min-height: 45vh;
}

.welcome-modal__img-container {
  min-height: 40vh;
}

.welcome-modal__gif {
  max-height: 70vh;
  max-width: 100%;
  border-radius: 6px;
}

.welcome-modal__gif--secondary {
  width: 90%;
  border-radius: 6px;
}

.welcome-modal__body {
  text-align: center;
}

.welcome-modal__body > h1 {
  font-size: 1.8rem;
  font-weight: bold;
}

.welcome-modal__body > h2 {
  font-size: 1.2rem;
  font-weight: lighter;
}

.welcome-modal__btn {
  margin-left: calc(50% - 5rem);
  margin-right: calc(50% - 5rem);
  width: 10rem;
  font-weight: bold;
}

.welcome-modal__progress-bar-container {
  padding-left: 25%;
  padding-right: 25%;
}

/* Account Modal */ 

.my-account__modal__header {
  padding-bottom: 0rem;
  border-bottom: none;
}

.my-account__modal__body {
  padding-top: 0rem;
}

.my-account__modal__footer {
  padding-top: 0rem; 
  padding-bottom: 2.5rem;
  border-top: none;
}

.my-account__user__avatar {
  position: relative;
  height: 70px;
  width: 70px;
  border-radius: 35px;
  overflow: hidden;
  object-fit: cover;
  cursor: pointer;
}

.my-account__user__avatar__wrapper {
  position: relative;
  padding-right: 0.5rem;
}

.account__user__avatar__upload {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.my-account__user__avatar__upload-icon {
  position: absolute; 
	bottom: 5px;
  right: 5px;
}

.my-account__user__avatar__upload-icon > .fa-stack > .fa-circle{
  color: rgba(255, 255, 255, 0.8);
}

.my-account__user__score {
  color: black;
  font-size: 1.2rem;
}

.my-account__user__handle {
  color: black;
  font-weight: bold;
  font-size: 1.8rem;
}

.my-account__btn {
  max-width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  font-weight: bold;
}

.my-account__btn--secondary {
  background-color: white;
  color: #F2A755; 
}

.my-account__btn--secondary:focus {
  background-color: white;
  color: #F2A755; 
}

.my-account__reauth-password {
  max-width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: .7rem;
}

.my-account__reauth-password__input {
  border-radius: 1.2rem !important;
  border: 1px solid #ced4da;
  height: 2.5rem;
}


/* Sign In */ 

.sign-in__nav {
  text-align: center;
  margin-left: 15%;
  margin-right: 15%;
  width: 70%;
}

.sign-in__nav__item--active {
  color: black;
  cursor: pointer;
}

.sign-in__nav__item--active > span {
  border-bottom: 1px solid black; 
  padding: 0.2rem;
}

.sign-in__nav__item--inactive {
  color: #929292;
  cursor: pointer;
}

.sign-in__modal__header {
  padding-bottom: 0rem;
  border-bottom: none;
}

.sign-in__modal__body {
  padding-top: 0rem;
}

.sign-in__modal__footer {
  padding-top: 0rem; 
  padding-bottom: 2.5rem;
  border-top: none;
}

.sign-in__form {
  text-align: center;
}

.sign-in__form__input {
  max-width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 0.3rem;
  margin-bottom: 0.4rem;
}

.sign-in__btn {
  max-width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  font-weight: bold;
}

.sign-in__btn--secondary {
  background-color: white;
  color: #F2A755; 
}

.sign-in__form__error-text {
  font-size: 0.8rem;
  color: red;
}

.sign-in__email_verificaiton {
  color: #929292;
  text-align: center;
}

.sign-in__forgot-password {
  cursor: pointer;
  color: #929292;
}

.sign-in__forgot-password:hover {
  color: black;
}

.sign-in__loader {
  margin-top: 4px;
  width: 1.8rem;
  height: 1.8rem;
  color: #F2A755;
}

.sign-in__legal {
  font-size: 0.5rem;
  text-align: center;
}

/* Leaderboard */

.leaderboar__heading {
  font-size: 2rem;
  font-weight: bold;
}

.user_specific_feed__infinite-scroll {
  overflow: scroll;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}

.leaderboar__infinite-scroll {
  overflow: scroll;
  overscroll-behavior: contain;
  height: calc(100vh - 220px);
  -webkit-overflow-scrolling: touch;
}

.leaderboar__infinite-scroll::-webkit-scrollbar {
  /* display: none; */
}

.leaderboar__infinite-scroll__load-more-button {
  color: #929292;
}

.leaderboar__infinite-scroll__load-more-button:hover {
  cursor: pointer;
  font-weight: bold;
}

.leaderboard__filter-bar {
  -ms-overflow-style: none;
}

.leaderboard__filter-bar::-webkit-scrollbar {
  display: none;
}

.leaderboard__filter-bar .btn {
  font-size: 0.8rem;
}

.leaderboard__filter-bar__refresh {
  border: none;
  color: #b1b1b1;
  cursor: pointer;
}

.leaderboard__filter-bar__refresh:hover {
  color: #929292;
}

.leaderboard-filter__btn--primary {
  background-color: #F2A755;
  color: white; 
}

.leaderboard-filter__btn--primary:hover {
  background-color: white;
  color: #F2A755; 
}

.leaderboard-filter__btn--secondary {
  background-color: white;
  color: #F2A755; 
}

.leaderboard-filter__btn--secondary:focus {
  background-color: white;
  color: #F2A755; 
}


.leaderboard-filter__btn--secondary:hover {
  background-color: #f2a655a7;
  color: white; 
}

.leaderboard_loader {
  width: 3rem;
  height: 3rem;
  color: #F2A755;
}

.leaderboard__loader__reload_symbol {
  color: #b1b1b1;
}

.leaderboard__tile__vote--active {
  color: #F2A755;
}

.leaderboard__tile {
  background-color: #F0F0F0;
  border: 1px solid #929292;
  border-radius: 10px;
  margin-top: 16px;
  margin-bottom: 36px;
}

.leaderboard__tile__main {
  background-color: white;
  margin: -16px;
  margin-bottom: -1px;
  border: 1px solid #929292;
  border-radius: 10px;
}

.leaderboard__tile__everything-but-votes{
  max-width: calc(100% - 6.5rem);
}

.leaderboard__tile__score {
  min-width: 7rem;
  min-height: 1.6rem;
}

.leaderboard__tile__score > span {
  position: absolute;
  bottom: 0.2rem;
}

.leaderboard__tile__comments {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.leaderboard__tile__comment__caret {
  color: #929292;
  font-size: 0.6rem;
}

.form-control {
  border-top-left-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  border-right: hidden;
}

.leaderboard__tile__comments__load-more-button{
  font-size: 1rem;
  padding-top: 0px;
  color: #929292;
}

.leaderboard__tile__comments__load-more-button:hover{
  color: black;
}

.leaderboard__tile__comments__button {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  padding: .3rem;
  padding-right: 0.7rem;
  padding-left: 0.4rem;
}

.leaderboard__tile__comment-button > span {
  white-space: nowrap;
}

.leaderboard__tile__handle {
  font-size: 1.5rem;
  padding-left: .8rem;
}

.leaderboard__tile__handle:hover {
  cursor: pointer;
  text-decoration: underline;
}

.leaderboard__tile__votes {
  position: static;
  right: 2.5rem;
  width: 6.5rem;
  max-width: 6.5rem;
  font-size: 2rem;
  font-weight: bold;
  line-height: 2rem;
}

.leaderboard__tile__votes > span {
  display: block;
  min-height: 100%;
  transform: translate(0, calc(50% - 3.3rem));
}

.leaderboard__tile__vote--inactive {
  color: #929292;
}

.leaderboard__tile__footer {
  font-size: 0.8rem;
}

.leaderboard__tile__comment-button {
  cursor: pointer;
}

.leaderboard__tile__comment-button > .fa-caret-down {
  color: #929292;
}

.leaderboard__tile__comment-button:hover {
  text-decoration: underline;
}

.user__level-1 {
  color: burlywood;
}

.user__level-2 {
  color: silver;
}

.user__level-3 {
  color: paleturquoise;
}

.user__level-4 {
  color: gold;
}

.user-info__avatar {
  position: relative;
  height: 70px;
  width: 70px;
  border-radius: 35px;
  overflow: hidden;
  object-fit: cover;
}

/* Magnets */

.magnets {
  padding-top: 40px;
}

.magnets__background-light-gray {
  background-color: #F0F0F0;
  border-radius: 0.6rem;
  box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.5);
  position: relative;
}

.magnets__background-white {
  background-color: white;  
  border-radius: 0.6rem;
}

.magnets__word-bank {
  height: calc(100vh - 420px);
  padding: 5rem; 
  width: 100%;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.magnets__heading > h1 {
  font-size: 1.7rem;
  text-align: center;
  font-weight: bold;
}

.magnets__heading > p {
  text-align: center;
}

.magnets__sentence {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 0rem;
}

.magnets__compose-sentence {
  border: solid 2px #F2A755 ;
  margin: 0px;
  border-radius: 0.6rem;
}

.magnets__compose-sentence__button {
  max-width: 3rem;
}

.magnets__compose-sentence__button > .btn {
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 1.1rem;
}

.magnets__compose-sentence__button--inactive {
  opacity: 0.5;
}

.magnets__sentence__composer-placehoder {
  font-style: italic;
  color: #929292;
  padding-top: .2rem;
  padding-left: 0.5rem;
  font-size: 1.2rem;
}

.magnets__compose-sentence__input {
  max-width: calc(100% - 3rem);
}

.magnets__sentence__word--first {
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1.2px solid black;
  border-top-left-radius: 1rem;
  padding-right: 0.4rem;
  padding-left: 0.7rem;
  margin-left: -1px;
  margin-top: -1px;
  text-transform: capitalize;
}

.magnets__sentence__word--middle {
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1.2px solid black;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin-left: -1px;
  margin-top: -1px;
}

.magnets__sentence__word--last {
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1.2px solid black;
  border-bottom-right-radius: 1rem;
  padding-left: 0.4rem;
  padding-right: 0.7rem;
  margin-left: -1px;
  margin-top: -1px;
}

.magnets__sentence__word--frist-last {
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1.2px solid black;
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding-right: 0.7rem;
  padding-left: 0.7rem;
  margin-left: -1px;
  margin-top: -1px;
  text-transform: capitalize;
}

.cursor_pointer {
  cursor: pointer;
}

.magnets__base-words__list {
  width: 100%;
}

.magnets__draggable_area {
  position: relative;
  width: 100%;
  margin: 0px;
  padding-right: 0px;
}

.magnets__post-loader {
  margin-top: 4px;
  width: 1.8rem;
  height: 1.8rem;
  color: #929292;
}

.magnet__word {
  position: relative;
  width: fit-content;
  background: white;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  padding: 2px;
  padding-left: 6px;
  padding-right: 6px;
  margin-right: 4px;
  margin-bottom: 6px;
  cursor: grab;
}

.magnet__word--active {
  color: black;
  border: 1px solid black;
}

.magnet__word--inactive {
  color: #929292;
  border: 1px solid #929292;
  opacity: 0.0;
}

.magnet__word__ad-link {
  position: absolute;
  text-align: center;
  bottom: -0.3rem;
  right: -0.25rem;
  background-color: #5d55f2;
  border-radius: 50%;
  font-size: 0.7rem;
  font-weight: bold;
  color: white;
  width: 1rem;
  height: 1rem;
}

.magnets__draggable_word_wrapper {
  position: relative;
  display: inline-block;
}

/* Footer */

.footer {
  position: fixed;
  background-color: #fdf6ed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 0.6rem;
  color: #929292;
}

.footer > a {
  color: #929292;
}

/* Media Queries */

@media screen and (max-width: 630px) {
  .navbar {
    padding-left: .2rem;
    padding-right: .2rem;
  }
  .navbar__user__avatar {
    top: 0px;
    height: 30px;
    width: 30px;
    border-radius: 15px;
  }
  .leaderboar__infinite-scroll {
    height: calc(100vh - 210px);
  }
  .magnets__word-bank {
    height: calc(100vh - 420px);
    padding: 3rem; 
  }.magnets {
    padding-top: 20px;
  }
}

@media screen and (min-width: 1500px) {
  .magnet__word {
    font-size: 1.0rem;
    border-radius: 0.4rem;
    padding: 3px;
    padding-left: 7px;
    padding-right: 7px;
  }
}

@media screen and (max-width: 420px) {
  .magnets__word-bank {
    height: calc(100vh - 400px);
    padding: 0.5rem; 
  }
}

/* Override */ 

.modal-content {
  border-radius: .6rem;
}

.form-control {
  border-radius: 10px;
  border: 1px solid #ced4da;
}

.input-group>.form-control:not(:last-child) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.remove-radius--right {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.remove-radius--left {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.input-group-prepend > span {
  font-size: 0.8rem;
}


.bold {
  font-weight: bold;
}