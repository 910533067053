.CalendarDay__selected {
  background: #F2A755;
  border: 1px solid #E89C39;
  color: white;
}

.CalendarDay__selected:hover {
  background: #E89C39;
  color: white;
}

.DateInput_input__focused {
  border-bottom: none;
  background-color: none;
}

.DateInput_input__small {
  font-size: 0.8rem;
}

.DateInput {
  background: transparent;
}

.DateInput_input {
  border-top-right-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  background-color: white;
  border-bottom: none;
}

.DateInput__small {
  width: 56px;
}

.SingleDatePickerInput {
  border-radius: 1.2rem;
  background: #F2A755;
  border: 2px solid #F2A755;
}

.SingleDatePickerInput_calendarIcon {
  color: white;
  padding: 0px;
  padding-right: 4px;
  padding-left: 2px;
  margin: 0 5px 0 10px;
}